import * as React from "react";
import type { PageProps } from "gatsby";
import BaseLayout from "../components/layout/BaseLayout";
import WithAuth from "../components/user/WithAuth";
import DashboardsPage from "../components/dashboards/DashboardsPage";
import GtagUserSetter from "../components/user/GtagUserSetter";
import AmplitudeUserSetter from "../components/user/AmplitudeUserSetter";
import MatomoTrack from "../components/user/MatomoTrack";

const DashboardsRoute = (props: PageProps) => {

    return (
        <WithAuth>
            <GtagUserSetter />
            <MatomoTrack />
            <AmplitudeUserSetter />
            <BaseLayout {...props}>
                <div className={"text-2xl px-6 pb-2 pt-4"}>
                    <h1>My Dashboards</h1>
                </div>
                <div className={"m-auto"}>
                    <DashboardsPage />
                </div>
            </BaseLayout>
        </WithAuth>
    );
};

export const Head = () => (
    <>
        <title>Runic | Dashboards</title>
    </>
)

export default DashboardsRoute;
